@import "../../assets/styles/scss/variable";

.activity-card {
  min-height: 111px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 1.25rem;
  border: 1px solid #f6f5f8;
  box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.02);
  border-radius: 4px;

  .activity-card-info {
    max-width: 550px;
    width: 100%;
    h3 {
      margin-bottom: 0.8125rem;
    }
    p {
      font-size: 0.6875rem;
      line-height: 14px;
      color: #979797;
    }
  }
  .btn {
    transition: all 0.3s ease-in;
  }
  .btn.size-sm {
    width: 111px;
    letter-spacing: -0.2px;
    font-weight: 700;
  }

  .btn.has-primary-theme {
    &:hover {
      background-color: darken($btn-bg-color-primary, 15%);
      color: lighten($btn-text-color-primary, 15%);
    }
  }

  .btn.has-secondary-theme {
    &:hover {
      background-color: lighten($btn-bg-color-secondary, 15%);
      color: darken($btn-text-color-secondary, 15%);
    }
  }
}

@media screen and (max-width: 750px) {
  .activity-card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    .activity-card-info {
      text-align: center;
    }
  }
}
