@import "./assets/styles/scss/variable";

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base-lg;
  background-color: $bg-color-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
