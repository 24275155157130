.skeleton {
  background: #ddd;
  overflow: hidden;
  border-radius: 4px;
}
.skeleton.text {
  width: 100%;
  height: 12px;
  margin-bottom: 0.5rem;
}
.skeleton.title {
  width: 50%;
  height: 44px;
  margin-left: 288px;
}

.skeleton.subtitle {
  width: 50%;
  height: 22px;
  margin-bottom: 2rem;
}

.skeleton.content {
  width: 100%;
  height: 100px;
  margin: 1.5rem 0;
}

.skeleton.button {
  max-width: 134px;
  height: 44px;
  width: 100%;
}
.skeleton.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}

.skeleton.banner {
  width: 100%;
  height: 175px;
}

.skeleton.info {
  width: 10%;
  height: 10px;
  margin-bottom: 1.5rem;
}

.skeleton.rating {
  width: 10%;
  height: 10px;
}
.skeleton.pdf {
  width: 100%;
  height: 506px;
}
.skeleton.video {
  margin-top: 3rem;
  width: 100%;
  height: 343px;
}

.skeleton-wrapper {
  position: relative;
  overflow: hidden;
}

/* animation effects */
.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
  .shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
