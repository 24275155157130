.pagination-link {
  font-weight: 600;
  cursor: pointer;
  font-size: 0.6875rem;
  line-height: 20px;
  text-decoration: underline;
  color: #ed1c24;
  opacity: 0.9;

  &.active {
    color: #000000;
  }
}
