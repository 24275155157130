.my-msg-board {
  background-color: #fff;
  max-width: 1132px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;

  border: 1px solid #f6f5f8;
  box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  .load-prev-conv {
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 20px;
    text-decoration: underline;
    color: #ed1c24;
    mix-blend-mode: normal;
    opacity: 0.9;
    text-align: center;
    margin-bottom: 0.75rem;
    cursor: pointer;
  }
}
