.chg-pwd-succ-page {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  .chg-pwd-succ-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    padding: 0.9375rem;
    text-align: center;
    svg {
      width: 3.6875rem;
      height: 3.6875rem;
      margin-bottom: 2.5rem;
    }
    h3 {
      max-width: 470px;
      width: 100%;
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    p {
      max-width: 244px;
      width: 100%;
      font-size: 0.875rem;
      margin-bottom: 2rem;
    }

    a {
      text-decoration: none;

      .btn.size-lg {
        width: 185px;
      }
    }
  }
}
