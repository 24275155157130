@import "../../../../assets/styles/scss/variable";
.view-profile-form {
  background-color: $bg-color-5;
  display: flex;
  justify-content: center;

  .form-container {
    max-width: 500px;
    padding: 30px 24px;
    width: 100%;
  }
}
