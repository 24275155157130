.previewImage {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  img {
    width: 161px;
    height: 161px;
    border-radius: 50%;
  }
}
