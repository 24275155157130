@import "../../../assets/styles/scss/variable";

.search-page {
  .search-page-header {
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    min-height: 142px;

    .header-content {
      max-width: 1132px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 56px;
      }
      .site-search-form {
        max-width: 360px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .form-control {
          margin: 0;
          margin-right: 1rem;
          flex-grow: 1;
        }
      }
      .btn.size-lg {
        width: 200px;
        height: 44px;
      }
    }
  }
}
