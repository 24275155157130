.reporting-menu {
  max-width: 832px;
  width: 100%;
  padding: 40px 0px;

  .table-container {
    background-color: #fff;
    box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
    border-radius: 8px;
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 10px;
  }
  table {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    color: #343a40;

    thead {
      tr {
        th {
          border: 1px solid #ecf0f2;
          min-width: 200px;
          font-weight: 700;
          text-align: left;
          color: #000;
          font-size: 0.75rem;
          text-transform: uppercase;
          padding: 15px;
          letter-spacing: 0.5px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-weight: 400;
          color: #979797;
          font-size: 0.875rem;
          border: 1px solid #ecf0f2;
          padding: 15px;
          min-width: 200px;
        }
      }
    }
  }
}
