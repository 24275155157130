.report-msg-popup {
  position: relative;
  .report-msg-form-container {
    max-width: 490px;
    width: 100%;
    margin: 0 auto;
    padding: 75px 20px;

    .report-msg-form {
      max-width: 450px;
      margin: 0 auto;
      .form-control {
        input:not([type="checkbox"]),
        textarea {
          max-width: 450px;
        }

        textarea {
          min-height: 180px;
        }
        .checkbox-option {
          label {
            margin-bottom: 0;
          }
        }

        label {
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #000000;
          margin-bottom: 0.5rem;
        }
      }

      .btn.size-xxlg {
        max-width: 450px;
        width: Min(calc(450px), calc(100%));
        border-radius: 6px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        margin-top: 1rem;
      }

      .error {
        margin-top: 1rem;
        color: red;
      }
    }
  }
}
