@import "../../../assets/styles/scss/variable";

.message-page {
  .message-page-header {
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    min-height: 146px;
    text-align: center;

    h1 {
      font-weight: 600;
      font-size: 2rem;
      line-height: 56px;
      margin-bottom: -0.5rem;
    }

    p {
      font-size: 0.875rem;
      line-height: 16px;
      color: #444444;
    }
  }
}
