.inviteconfirmpopup {
  min-height: 227px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 56px;
    color: #000000;
  }
  h3 {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    line-height: 22px;
    letter-spacing: 1px;
    color: #494b4e;
  }
  .action-btns {
    display: flex;
    .btn:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
