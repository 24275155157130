.conference-header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .filter-action {
    max-width: 510px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-button {
      margin-right: 0.5rem;
      .btn.size-lg {
        width: 102px;
        height: 30px;
      }
    }
    .filter-bar {
      width: 72%;
      min-height: 46.5px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .filter-event {
        font-family: Nunito;
        font-weight: 600;
        line-height: 20px;
        font-size: 0.875rem;
        letter-spacing: -0.2px;
        color: #000000;
        cursor: pointer;
        width: 100px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-shadow: 0px 1px 2px rgba(22, 20, 37, 0.06);
        border-radius: 6px;
        transition: all 0.3s ease-in;
      }

      .filter-event-active {
        width: 100px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ed1c24;
        box-shadow: 0px 1px 2px rgba(22, 20, 37, 0.06);
        border-radius: 6px;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 20px;
        letter-spacing: -0.2px;
        color: #ffffff;
      }
    }
  }
}
