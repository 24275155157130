@import "../../../../assets/styles/scss/variable";
.reg-form {
  background-color: $bg-color-5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-container {
    max-width: 500px;
    padding: 48px 24px 131px 24px;
    width: 100%;

    .compliance-container {
      h3 {
        text-align: center;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 22px;

        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 0.625rem;
      }

      p {
        font-size: 0.875rem;
        line-height: 16px;
        text-align: center;
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 1.75rem;
      }
    }

    button:disabled {
      cursor: not-allowed;
    }

    .error {
      color: red;
      margin-top: 1rem;
    }
  }
}
