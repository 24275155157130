.msg-succ-page {
  height: 80vh;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  .msg-succ-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    padding: 78px 15px;
    text-align: center;
    svg {
      width: 3.6875rem;
      height: 3.6875rem;
      margin-bottom: 2.5rem;
    }
    h3 {
      max-width: 470px;
      width: 100%;
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 2.125rem;
      font-family: Open Sans;

      line-height: 56px;
    }
    p {
      max-width: 301px;
      width: 100%;
      margin-bottom: 2.125rem;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 22px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: #494b4e;
    }

    a {
      text-decoration: none;

      .btn.size-lg {
        width: 275px;
      }
    }
  }
}
