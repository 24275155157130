@import "../../assets/styles/scss/variable";

.inner-modal-priv {
  height: 100%;
  position: relative;
  min-height: 435px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #f3f3f3;
  background-color: $bg-color-4;

  svg {
    width: 2rem;
    height: 2rem;
  }
  .terms {
    height: 100%;
    padding: 20px;

    overflow: scroll;
    h1 {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 56px;
      margin-bottom: 1rem;
    }
    .content {
      max-width: 1120px;
      width: 100%;
      margin: 0 auto;
      h3 {
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 16px;
        color: #f4574b;
        margin: 1rem 0;
      }

      p {
        font-size: 0.6875rem;
        line-height: 14px;
        color: #979797;
        margin-bottom: 0.5rem;
        span {
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
  .action-btns {
    display: flex;
    margin: 1rem 0;

    .btn:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}
