@import "../../assets/styles/scss/variable";

.btn {
  height: 32px;
  border: 0;
  font-family: $font-family-btn;
  font-size: 14px;
  font-weight: 700;
  user-select: none;
  white-space: nowrap;
  letter-spacing: -0.2px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  &.size {
    &-sm {
      width: 95px;
    }

    &-sml {
      width: 128px;
    }
    &-md {
      padding: 0 15px;
    }
    &-lg {
      width: 163px;
    }

    &-xlg {
      width: Min(calc(382px), calc(100vw - 50px));
      height: 44px;
    }

    &-xxlg {
      width: Min(calc(450px), calc(100vw - 48px));
      height: 44px;
      font-weight: 700;
    }
  }
  &.has {
    &-primary-theme {
      background-color: $btn-bg-color-primary;
      color: $btn-text-color-primary;
      transition: all 0.3s ease-in;

      &:hover {
        background-color: darken($btn-bg-color-primary, 15%);
        color: lighten($btn-text-color-primary, 15%);
      }
    }
    &-secondary-theme {
      background-color: $btn-bg-color-secondary;
      color: $btn-text-color-secondary;
      border: 1px solid $color-primary;
      box-shadow: 0px 1px 2px rgba(22, 20, 37, 0.06);
      border-radius: 4px;
    }
  }
}
