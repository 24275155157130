.message-card {
  max-width: 1072px;
  width: 100%;
  border: 1px solid #dbdbe5;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  .msg-info {
    display: flex;
    .img-container {
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
      margin-right: 0.5rem;
    }
    .msg-content {
      max-width: 783px;
      width: 100%;
      margin-bottom: 1.25rem;
      .sender-info {
        display: flex;
        margin-bottom: 0.625rem;
        .sender-name {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 16px;
          margin-right: 0.5rem;
        }
      }
      .message-text {
        font-size: 0.6875rem;
        line-height: 14px;
        color: #979797;
      }
    }
    .full-width {
      max-width: 957px;
      width: 100%;
    }
  }

  .msg-view-btn {
    align-self: center;
    .btn.size-lg {
      width: 104px;
      height: 44px;
    }
  }
}
