.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .inner-modal {
    max-width: 800px;
    width: 80%;
    height: 100%;
    background-color: #fff;
  }
}
