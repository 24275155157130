.service-card {
  max-width: 400px;
  width: 100%;

  margin-right: 32px;

  .card-title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;

    margin-bottom: 1.125rem;
  }
  .card-body {
    width: 100%;
    min-height: 420px;
    margin-bottom: 1rem;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #f6f5f8;
    box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.02);
    border-radius: 4px;

    .card-body-title {
      font-size: 1rem;
      font-weight: normal;
      line-height: 22px;
      margin-bottom: 1.375rem;
      margin-top: 0.8125rem;
    }

    .card-img-container {
      img {
        width: 100%;
      }
    }
    .card-body-content {
      .btn-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        button:first-child {
          background: #fafafa;
          border: none;
          border-radius: 4px;
          max-width: 271px;
          width: 100%;

          font-family: Open Sans;
          font-weight: 600;
          font-size: 0.6875rem;
          line-height: 14px;
          color: #3a4248;
        }

        a {
          max-width: 80px;
          width: 100%;
        }
        button:last-child {
          background: #fff0f1;
          border: none;
          border-radius: 4px;

          max-width: 80px;
          width: 100%;

          font-family: Open Sans;
          font-weight: 600;
          font-size: 0.6875rem;
          line-height: 20px;
          text-decoration: underline;
          text-decoration-color: #ed1c24;

          color: #ed1c24;

          cursor: pointer;
        }
      }
    }
  }
  .card-footer {
    a {
      text-decoration: none;
      button {
        width: 100%;
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-shadow: 0px 1px 2px rgba(22, 20, 37, 0.06);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
