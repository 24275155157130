.community-member-card {
  width: 100%;
  border: 1px solid #dbdbe5;
  background-color: #fff;
  border-radius: 4px;
  .upper-strip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px 12px 12px;
    .member-basic-info {
      display: flex;
      align-items: center;
      .profile-img-container {
        width: 56px;
        height: 56px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        margin-right: 0.5rem;
      }

      .profile-basic-info {
        h3 {
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 16px;
          margin-bottom: 0.625rem;
        }
        .profile-email-number {
          font-size: 0.6875rem;
          line-height: 14px;
          color: #979797;

          span:nth-child(2) {
            margin: 0rem 0.25rem;
          }
        }
      }
    }
    .profile-act-btns {
      display: flex;
      .view-profile-btn {
        margin-right: 0.5rem;
      }
      .btn.size-lg {
        width: 112px;
      }
    }
  }
  .lower-strip {
    background-color: #f5f7f8;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    .member-tag {
      display: flex;
      align-items: center;
      .space-breaker {
        color: #e0e0e0;
        height: 20px;
        margin: 0 0.5rem;
      }
      p {
        font-weight: 600;
        font-size: 0.6875rem;
        line-height: 14px;
        color: #979797;
      }
    }
  }
}
