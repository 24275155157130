.article {
  max-width: 832px;
  width: 100%;
  font-size: 11px;
  line-height: 14px;
  color: #979797;
  .article-top {
    background: #fff;
    border: 1px solid #f6f5f8;
    box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    .article-main {
      padding: 1.5rem;
      border: 1px solid #f0f0f0;
      .article-img {
        img {
          width: 100%;
          max-height: 175px;
        }
      }
      .article-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1.5rem 0;

        p {
          span {
            color: #ed1c24;
            text-decoration: underline;
          }
        }
      }

      .article-content {
        text-align: justify;

        p {
          margin-bottom: 1.5rem;
          font-size: 0.875rem;
          // font-size: 1rem;
        }
        img {
          width: 100%;
        }
        h3 {
          font-size: 1rem;
          font-weight: 700;
          margin-bottom: 1.5rem;
        }
        table {
          width: 100%;
          font-size: 0.6875rem;
          margin: 2rem 0;
          border-collapse: collapse;
          thead {
            th {
              border: 1px solid #979797;
              vertical-align: top;
              text-align: left;
              padding: 0.5rem;
            }
          }
          tbody {
            td {
              text-align: left;
              vertical-align: top;
              padding: 0.5rem;
              border: 1px solid #979797;
              a {
                color: #f4574b;
              }
            }
          }
        }
      }
    }

    .article-interaction {
      padding: 1.5rem;

      border: 1px solid #f0f0f0;

      .comment-count {
        display: flex;
        align-items: center;
        svg {
          margin-right: 0.5rem;
        }
        p {
          color: #ed1c24;
          text-decoration: underline;
        }
      }
      .instruction {
        color: #ed1c24;
        text-align: center;
        margin-bottom: 1.5rem;
        font-weight: 700;
      }

      .comments {
        padding: 1rem 0;
        .view-all-comments,
        .view-recent-comments {
          p {
            text-align: center;
            font-weight: 600;
            text-decoration: underline;
            color: #ed1c24;
            opacity: 0.9;
            cursor: pointer;
          }
        }

        .view-recent-comments {
          p {
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    .article-add-comment {
      padding: 1.5rem;
      .new-comment-form-container {
        .new-comment-form {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .form-control {
            display: flex;
            flex-direction: column;
            width: 82.5%;
            margin-bottom: 0;
            label {
              font-family: Nunito;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.24px;

              color: #000000;
            }
            textarea {
              height: 44px;
              max-width: 100%;

              width: 100%;
              border: 1px solid #d2d8df;
              box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
              border-radius: 6px;
            }
          }

          .btn.size-lg {
            width: 16%;
            height: 44px;
          }
        }
        .error {
          margin-top: 1rem;
          color: red;
        }
      }
    }
  }

  .article-footer {
    padding: 1.5rem 0 0.625rem 0;
    .contribute-buttons {
      display: flex;

      .btn.size-lg {
        max-width: 227px;
        width: 100%;
        height: 41px;

        &:not(:last-child) {
          margin-right: 1.6875rem;
        }
      }
    }
  }
}
