.reply-card {
  border: 1px solid #dbdbe5;
  border-radius: 4px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.875rem;

  &:not(:last-child) {
    margin-bottom: 0.875rem;
  }

  .reply-main {
    display: flex;
    .author-img-cont {
      margin-right: 0.5rem;
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
    }
    .reply-info {
      max-width: 543px;
      width: 100%;
      h3 {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #000;
        margin-bottom: 0.625rem;
      }
    }
  }

  .reply-act {
    color: #ed1c24;
    align-self: flex-end;
    text-decoration: underline;
    mix-blend-mode: normal;
    opacity: 0.9;
    display: flex;
    p {
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }
}
