.msg-counter-container {
  background-color: #ed1c24;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  .msg-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 0.6875rem;
  }
}
