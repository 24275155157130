.cme-card {
  min-height: 461px;
  max-width: 256px;
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #f6f5f8;
  box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.375rem;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  .cme-card-info {
    .cme-card-thumbnail {
      img {
        width: 100%;
      }
      margin-bottom: 1.25rem;
    }
    .cme-card-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 1.25rem;
    }
    .cme-card-content {
      font-size: 11px;
      line-height: 14px;
      color: #979797;
    }
  }

  .cme-card-action {
    display: flex;
    justify-content: space-between;
    .cme-card-files {
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
      display: flex;
      .cme-card-doc {
        margin-right: 0.75rem;
      }
    }
  }
}
