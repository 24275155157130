@import "../../../../assets/styles/scss/variable";
.search-page-body {
  border: 1px solid #f3f3f3;
  background-color: $bg-color-4;
  min-height: 409px;
  padding: 47px 20px;

  .search-matches {
    max-width: 1124px;
    width: 100%;
    margin: 0 auto;

    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 22px;
      letter-spacing: 1px;
    }

    .search-matched-articles {
      margin-bottom: 76px;
      & > h3 {
        text-transform: uppercase;
        margin-bottom: 1.125rem;
      }

      .article-info-card {
        max-width: 1124px;
        .article-header {
          .article-info-content {
            max-width: 90%;
          }
          .article-img-container {
            img {
              width: 96px;
            }
          }
        }
      }
    }
    .search-matched-conf {
      margin-bottom: 76px;
      & > h3 {
        margin-bottom: 1.125rem;
        text-transform: uppercase;
      }
      .conf-display-card:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
    .search-matched-modules {
      margin-bottom: 76px;
      & > h3 {
        margin-bottom: 1.125rem;
        text-transform: uppercase;
      }
      .cme-display-card:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    .search-matched-profiles {
      & > h3 {
        margin-bottom: 1.125rem;
        text-transform: uppercase;
      }
      .community-member-card:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .no-result-found {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 409px;
    p {
      color: red;
      font-weight: bold;
    }
  }
}
