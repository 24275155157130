@import "../../../../assets/styles/scss/variable";
.search-conf-page-body {
  position: relative;
  border: 1px solid #f3f3f3;
  background-color: $bg-color-4;
  min-height: 409px;
  padding: 47px 20px;

  .search-matches {
    max-width: 1124px;
    width: 100%;
    margin: 0 auto;

    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 22px;
      letter-spacing: 1px;
    }

    .search-matched-conf {
      margin-bottom: 76px;
      & > h3 {
        text-transform: uppercase;
        margin-bottom: 1.125rem;
      }
      .instruction {
        color: #ed1c24;
        margin-bottom: 1.5rem;
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .conf-display-card:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
}
