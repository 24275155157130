@import "../../../assets/styles/scss/variable";

.my-message-clth-page {
  .my-message-clth-page-header {
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    min-height: 142px;

    .header-content {
      max-width: 1132px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .action-btns {
        display: flex;

        .btn:first-child {
          width: 164px;
          height: 44px;
          margin-right: 1.5625rem;
        }

        .btn:not(:first-child) {
          width: 134px;
          height: 44px;
        }
      }
      h1 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 56px;
      }
    }
  }
}
