.filter-mod-result {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;

  & > div:last-child {
    border-bottom: none;
  }

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .title {
    h3 {
      margin-bottom: 1.5rem;
    }

    border-bottom: 1px solid #979797;
  }

  .form-control {
    display: flex;
    flex-direction: column;
    max-width: 267px;
    margin-left: auto;
    margin-top: 28px;
    select {
      width: 100%;
      min-height: 38px;
      padding: 0.5rem 0.6875rem;
    }
  }
  .bookmarked-results {
    border-bottom: 1px solid #979797;
    h3 {
      margin-bottom: 2rem;
    }
  }
  .other-results {
    h3 {
      margin: 2rem 0;
    }
  }
}
