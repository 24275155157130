@import "../../assets/styles/scss/variable";

.form-control {
  margin-bottom: 30px;
  label {
    font-family: Nunito;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0.25rem;
  }

  .pwd-icon {
    position: relative;
    width: 27px;
    height: 18px;
    left: 90%;
    bottom: 30px;
  }

  .pwd-icon + .error {
    margin-top: -6px !important;
  }

  input:visited + .pwd-icon {
    outline: 1px solid green;
  }

  input:not([type="checkbox"]),
  textarea {
    max-width: 450px;
    min-height: 44px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d2d8df;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    padding: 0.75rem 1.125rem;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #494b4e;

    transition: all 0.3s ease-in;

    &:hover {
      border-color: $color-primary;
    }

    &:focus {
      outline: none;
      border-color: $color-primary;
    }
  }

  .checkbox-option {
    max-width: 450px;
    width: 100%;
    min-height: 64px;
    display: flex;
    align-items: center;
    padding: 0 0.875rem;
    margin-bottom: 1.75rem;

    background: #ffffff;
    border: 1px solid #d2d8df;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;

    input[type="checkbox"] {
      width: 1.75rem;
      height: 1.5rem;
      margin-right: 0.875rem;
    }

    label {
      font-family: Nunito;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #494b4e;
    }
  }

  select {
    max-width: 450px;
    width: 100%;
    min-height: 44px;
    background: #ffffff url("../../assets/images/dropdown.svg") no-repeat;
    border: 1px solid #d2d8df;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    padding: 0.75rem 1.125rem;
    letter-spacing: -0.24px;
    color: #494b4e;

    appearance: none;
    position: relative;
    background-position: right 1.125rem top 50%;

    transition: all 0.3s ease-in;

    &:hover {
      border-color: $color-primary;
    }

    &:focus {
      outline: none;
      border-color: $color-primary;
    }
  }

  .upload-button,
  .remove-button {
    display: block;
    width: 30%;
    border: none;
    background-color: inherit;
    margin: 0.25rem auto;
    p {
      font-family: $font-family-btn;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      text-decoration: underline;
    }
  }

  .error {
    color: red;
    white-space: pre-wrap;
  }
  .success {
    color: green;
    white-space: pre-wrap;
  }
}
