@import "../../../assets/styles/scss/variable";

.cookie-pol-page {
  .cookie-pol-header {
    border-top: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    padding: 0 20px;
    min-height: 146px;

    h1 {
      max-width: 1120px;
      width: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 2rem;
      line-height: 56px;
      margin-bottom: -0.5rem;
    }
  }
}
