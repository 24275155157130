.accounts-body {
  max-width: 832px;
  width: 100%;

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 0.9375rem;
    text-transform: uppercase;
  }

  hr {
    margin-bottom: 1.5rem;
  }

  .account-actions {
    .action-btn-container {
      display: flex;
      justify-content: space-between;

      .btn.size-lg {
        cursor: pointer;
        width: 30%;
      }
    }
  }
}
