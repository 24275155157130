.profile-info-card {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  max-width: 256px;
  width: 100%;
  min-height: 176px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;

  .profile-img-container {
    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }
  }

  h4 {
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 0.625rem;
    margin-top: 0.5rem;
  }
  p {
    font-size: 0.875rem;
    line-height: 16px;
    color: #979797;
    margin-bottom: 0.75rem;
    cursor: pointer;
  }
}
