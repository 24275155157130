@import "../../../../assets/styles/scss/variable";

.terms-cond-body {
  border: 1px solid #f3f3f3;
  background-color: $bg-color-4;
  padding: 40px 20px;
  min-height: 409px;

  .content {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    h3 {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 16px;
      color: #f4574b;
      margin: 1rem 0;
    }

    p {
      font-size: 0.75rem;
      text-align: justify;
      line-height: 14px;
      color: #979797;
      margin-bottom: 0.5rem;
      span {
        font-weight: 600;
        color: #000;
      }
    }
    table {
      width: 100%;
      font-size: 0.6875rem;
      margin: 2rem 0;
      border-collapse: collapse;
      thead {
        background-color: #333;
        color: #fff;
        th {
          text-align: left;
          padding: 0.5rem;
        }
      }
      tbody {
        td {
          text-align: left;
          padding: 0.5rem;
          border: 1px solid #979797;
          a {
            color: #f4574b;
          }
        }
      }
    }

    .address {
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
        font-weight: bold;
        color: #000;
      }
    }
  }
}
