.mem-card {
  border: 1px solid #eaeaea;
  padding: 1rem;
  background: #ffffff;

  h3 {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  a {
    display: block;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    color: #ed1c24;
  }

  .sample-mem-profile {
    display: flex;
    .mem-card-profile {
      margin: 1rem 0;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
      }
    }
  }
  .no-users-found {
    color: red;
    font-weight: bold;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}
