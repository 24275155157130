.reg-success-page {
  .reg-success-content {
    border-top: 1px solid #e8e8e8;
    min-height: 555px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-weight: 600;
      font-size: 2rem;
      line-height: 56px;
      margin-bottom: 0.875rem;
    }

    .reg-info {
      max-width: 360px;
      p {
        font-size: 0.875rem;
        line-height: 16px;
        text-align: center;
        color: #444444;
      }
      p:first-child {
        margin-bottom: 1.5rem;
      }

      a {
        text-decoration: none;
      }

      .btn {
        margin: 0 auto;
      }
    }
  }

  .reg-success-page-footer {
    border: 1px solid #e8e8e8;
    min-height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-content {
      max-width: 250px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 0.875rem;
        line-height: 16px;
      }

      .btn.size-lg {
        width: 104px;
      }
    }
    a {
      text-decoration: none;
    }
  }
}
