@import "../../../assets/styles/scss/variable";
.invite-friend-body {
  max-width: 832px;
  width: 100%;
  .invite-friend-form-container {
    max-width: 490px;
    width: 100%;
    padding: 20px 0;

    .invite-friend-form {
      max-width: 450px;
      .form-control {
        input:not([type="checkbox"]) {
          max-width: 450px;
        }

        label {
          display: inline-block;
          font-weight: bold;
          font-size: 0.9375rem;
          line-height: 20px;
          margin-bottom: 0.25rem;
        }
      }

      .btn.size-xxlg {
        max-width: 450px;
        width: Min(calc(450px), calc(100%));
        border-radius: 6px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        margin-top: 1rem;
      }

      .error {
        margin-top: 1rem;
        color: red;
      }
    }
  }
}
