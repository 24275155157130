.module {
  max-width: 832px;
  width: 100%;
  font-size: 11px;
  line-height: 14px;
  background-color: #fff;
  color: #979797;
  padding: 1.5rem;
  border: 1px solid #f6f5f8;
  box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  .about {
    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 2rem;
    }
    p {
      font-size: 0.875rem;
      line-height: 16px;
      margin-bottom: 1.25rem;
      span {
        color: #000;
        font-weight: 600;
      }
    }
  }
  .module-content {
    .pdf-content {
      padding: 2rem 4rem;
      border: 1px solid #f6f5f8;
      box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.02);
      border-radius: 4px;
      margin: 2rem 0;
    }

    .video-content {
      border: 1px solid #f1f1f6;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04))
        drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.06));
      border-radius: 6px;
      padding: 1rem;
      margin-bottom: 4rem;
    }
  }

  .resources {
    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 1.25rem;
    }
    hr {
      border: 0.1px solid #f0f0f0;
      margin: 2.125rem 0;
    }
    p {
      font-size: 0.875rem;
      line-height: 16px;
      margin-bottom: 1.25rem;
      a {
        text-decoration: none;
        color: #ed1c24;
        font-weight: 600;
      }
    }
  }

  .module-action {
    display: flex;
    justify-content: flex-end;
    .btn.size-sml {
      width: 125px;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
