.update-profile-heading {
  min-height: 146px;
  border: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 56px;
  }
}

@media screen and (max-width: 850px) {
  .update-profile-heading {
    text-align: center;
    h1 {
      line-height: 40px;
    }
  }
}
