.community-body {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}

@media screen and (max-width: 1150px) {
  .community-body {
    justify-content: center;
    padding: 40px 20px;
  }
}
