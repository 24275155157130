.information-heading {
  background-color: #fff;
  min-height: 142px;
  display: flex;
  align-items: center;
  border: 1px solid #eaeaea;
  .title-container {
    display: flex;
    justify-content: space-between;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;

    h1 {
      font-weight: 600;
      margin-left: 288px;
      font-size: 32px;
    }

    input[type="search"] {
      max-width: 268px;
      width: 100%;
      height: 54px;
      border: 1px solid #dbdbe5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 1.125rem;

      &::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #212121;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .information-heading {
    padding: 0 20px;
    .title-container {
      text-align: center;
      h1 {
        margin-left: 0;
      }
    }
  }
}
