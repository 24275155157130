@import "../../../../assets/styles/scss/variable";

.comm-mem-page-body {
  border: 1px solid #f3f3f3;
  background-color: $bg-color-4;
  min-height: 409px;
  padding: 83px 15px 45px;

  .no-user-found {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: red;
      font-size: 1rem;
      font-weight: bold;
      margin-top: 2.5rem;
    }
  }
}
