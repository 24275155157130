@import "../../../assets/styles/scss/variable";

.my-message-page {
  .my-message-page-header {
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    min-height: 142px;

    h1 {
      max-width: 1132px;
      width: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 2rem;
      line-height: 56px;
    }
  }
}
