.invite-friend-success-body {
  max-width: 832px;
  width: 100%;
  h3 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 56px;
    margin-bottom: 0.875rem;
  }
  p {
    font-size: 0.875rem;
    line-height: 16px;
    color: #444444;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
