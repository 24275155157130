@import "../../assets/styles/scss/variable";
.saved-article-info-card {
  max-width: 100%;
  width: 100%;
  border: 1px solid #dbdbe5;
  border-radius: 4px;
  margin-bottom: 1rem;
  cursor: pointer;

  .article-header {
    padding: 1rem;
    display: flex;
    .article-img-container {
      margin-right: 1rem;
      img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
      }
    }
    .article-info-content {
      width: 100%;
      .article-title {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 0.625rem;
      }
      .article-desc {
        font-size: 0.6875rem;
        line-height: 14px;

        color: #979797;
      }
    }
  }

  .article-footer {
    padding: 0.5rem 1rem;
    background-color: #f5f7f8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .article-action {
      display: flex;

      p {
        font-weight: 600;
        font-size: 0.6875rem;
        line-height: 20px;
        text-decoration: underline;
        text-decoration-color: #ed1c24;
        color: #ed1c24;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .article-detail {
      display: flex;
      align-items: center;
      p {
        font-weight: 600;
        font-size: 0.6875rem;

        color: #979797;

        span {
          color: $color-primary;
          text-decoration: underline;
        }
      }

      .comment-count {
        svg {
          margin-right: 0.5rem;
        }
        display: flex;
        align-items: center;
      }

      .space-breaker {
        color: #e0e0e0;
        height: 25px;
        margin: 0 0.5rem;
      }
    }
  }
}
