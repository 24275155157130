@import "../../../assets/styles/scss/variable";

.reply-board-form {
  .reply-board-form-container {
    width: 100%;
    .reply-board-form {
      .form-control {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        input:not([type="checkbox"]),
        textarea {
          max-width: 1072px;
          min-height: 96px;
        }

        label {
          font-family: Nunito;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #000000;
          margin-bottom: 0.5rem;
        }
      }

      .btn.size-xxlg {
        max-width: 104px;
        width: Min(calc(104px), calc(100%));
        border-radius: 6px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        margin-top: 1rem;
      }

      .error {
        margin-top: 1rem;
        color: red;
      }
    }
  }
}
