.pdf-viewer {
  .full-pdf {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    a {
      text-decoration: none;
      .btn.size-sml {
        width: 112px;
        border: 1px solid #c9c9d5;
      }
    }
  }

  .react-pdf__Document {
    margin-bottom: 0.5rem;
    border: 1px solid #e4e4e4;
    // .react-pdf__Page {
    //   .react-pdf__Page__canvas {
    //     width: 100% !important;
    //   }
    // }
  }

  .control-button {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;

    .btn.size-sml {
      width: 112px;
      border: 1px solid #c9c9d5;
    }
  }
}
