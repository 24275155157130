@import "../../assets/styles/scss/variable";
.footer {
  max-width: 1120px;
  width: 100%;
  min-height: 207px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footer-links {
    list-style: none;
    a {
      text-decoration: none;
      .footer-link {
        color: $color-black;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .footer-logo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    svg {
      margin-bottom: 0.875rem;
      width: 60%;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      margin: 0;
    }
  }
}

@media screen and (max-width: 1120px) {
  .footer {
    padding: 0 20px;
  }
}

@media screen and (max-width: 900px) {
  .footer {
    padding: 20px;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      &:nth-child(-n + 2) {
        margin-bottom: 1.25rem;
        align-self: flex-start;
      }
    }
    .footer-logo-container {
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    text-align: center;
    & > div {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }

    .feedback {
      display: flex;
      justify-content: center;
      margin-bottom: 1.25rem;
    }
    .footer-logo-container {
      align-items: center;
    }
  }
}
