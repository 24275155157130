.cancel-popup {
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    fill: #ed1c24;
    width: 2.5rem;
    height: 2.5rem;
  }
}
