@import "../../../assets/styles/scss/variable";

.filter-mod-page {
  .filter-mod-page-header {
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    min-height: 142px;

    .header-content {
      max-width: 1132px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 56px;
      }
    }
  }
}
