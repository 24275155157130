.card {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  max-width: 256px;
  width: 100%;
  min-height: 100px;
  padding: 1rem;
  a {
    text-decoration: none;
    h3 {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 1rem;
      color: #000;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0.875rem;
      color: #979797;
    }
  }
}
