@import "../../../../assets/styles/scss/variable";

.about-us-body {
  border: 1px solid #f3f3f3;
  background-color: $bg-color-4;
  padding: 40px 20px;
  min-height: 409px;

  .content {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    h3 {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 1.25rem;
    }

    p {
      font-size: 0.6875rem;
      line-height: 14px;
      color: #979797;
      margin-bottom: 1.125rem;
      span {
        font-weight: 600;
        color: #000;
      }
    }
  }
}
