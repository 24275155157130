.verify-email-req-page {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  .verify-email-req-page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    h3 {
      color: #ed1c24;
    }
  }
}
