.activity-menu {
  max-width: 832px;
  width: 100%;
  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;
    margin-bottom: 26px;

    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 750px) {
  .activity-menu {
    h3 {
      font-size: 0.875rem;
      letter-spacing: 0.1px;
    }
  }
}
