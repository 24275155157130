@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
// Font family
$font-family-base: "Open Sans", sans-serif;
$font-family-btn: "Nunito", sans-serif;

// Base font size for screens
$font-size-base-lg: 16px;

// Theme colors
$color-primary: #ed1c24;
$color-black-off: #4c4141;
$color-white: #fff;
$color-black: #000;

// Layout background(bg) colors
$bg-color-primary: #ffffff;
$bg-color-secondary: #f6f6f6;
$bg-color-tertiary: #fcfcfd;
$bg-color-4: #fafafa;
$bg-color-5: #f5f5f5;

// Button styling
$btn-bg-color-primary: #ed1c24;
$btn-bg-color-secondary: #ffffff;
$btn-text-color-primary: #ffffff;
$btn-text-color-secondary: #ed1c24;
