.share-popup {
  position: relative;
  h2 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 56px;
    text-align: center;
    color: #000000;
    padding: 2.5rem 0;
  }
  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #494b4e;
  }

  .share-form-container {
    max-width: 490px;
    width: 100%;
    margin: 0 auto;
    padding: 36px 20px 60px;

    .share-form {
      max-width: 450px;
      margin: 0 auto;
      .form-control {
        input:not([type="checkbox"]),
        textarea {
          max-width: 450px;
        }

        .react-select-container {
          .react-select__single-value {
            .async-select-opt-label {
              display: flex;
              align-items: center;
              img {
                margin-right: 0.75rem;
                border-radius: 50%;
                height: 1.875rem;
                width: 1.875rem;
              }
              p {
                font-family: Nunito;
                font-size: 0.9375rem;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #494b4e;
              }
            }
          }

          .react-select__option {
            .async-select-opt-label {
              display: flex;
              align-items: center;
              img {
                margin-right: 0.75rem;
                border-radius: 50%;
                height: 1.875rem;
                width: 1.875rem;
              }
            }
          }
        }

        textarea {
          min-height: 180px;
        }

        label {
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: #000000;
          margin-bottom: 0.5rem;
        }
      }

      .btn.size-xxlg {
        max-width: 450px;
        width: Min(calc(450px), calc(100%));
        border-radius: 6px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        margin-top: 1rem;
      }

      .error {
        margin-top: 1rem;
        color: red;
      }
    }
  }
}
