.community-info {
  max-width: 256px;
  width: 100%;
  margin-top: -150px;

  .card:last-child {
    margin-top: -20px;
  }
}

@media screen and (max-width: 1150px) {
  .community-info {
    display: none;
  }
}
