@import "../../../../assets/styles/scss/variable";
.action-circle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .action-circle {
    width: 160px;
    height: 160px;
    border-radius: 86px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      width: 74px;
      height: 91px;
    }

    h3 {
      color: $color-white;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .circle-bg-red {
    background-color: $color-primary;
    cursor: pointer;
  }

  .circle-bg-dark {
    background-color: $color-black;
  }

  .action-circle-title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;

    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
