.conf-display-card {
  width: 100%;
  border: 1px solid #dbdbe5;
  background-color: #fff;
  border-radius: 4px;
  .upper-strip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px 12px 12px;
    .card-info {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 16px;
        margin-bottom: 0.625rem;
      }
      .description {
        font-size: 0.6875rem;
        line-height: 14px;
        color: #979797;
        span {
          color: #000000;
          margin-right: 0.5rem;
        }
      }
    }
    .module-act-btns {
      display: flex;
      .btn.size-lg {
        width: 112px;
        border: 1px solid #c9c9d5;
        cursor: pointer;
      }
    }
  }
  .lower-strip {
    background-color: #f5f7f8;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    .member-tag {
      display: flex;
      align-items: center;
      .space-breaker {
        color: #e0e0e0;
        height: 20px;
        margin: 0 0.5rem;
      }
      p {
        font-weight: 600;
        font-size: 0.6875rem;
        line-height: 14px;
        color: #979797;
      }
    }
  }
}
