@import "../../../../../assets/styles/scss/variable";

.filter-mod-page-form {
  max-width: 1120px;
  margin: 0 auto;
  margin-bottom: 57px;
  .filter-mod-form-container {
    .form-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 1.25rem;
    }
    .filter-mod-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .form-control {
        max-width: 267px;
        width: 100%;
        margin: 0;
        input:not([type="checkbox"]),
        select {
          width: 100%;
          min-height: 38px;
          padding: 0.5rem 0.6875rem;
        }

        .checkbox-option {
          margin-top: 16px;
          width: 100%;
          min-height: 38px;
          margin-bottom: 0;
          input[type="checkbox"] {
            width: 0.875rem;
            height: 0.875rem;
            margin-right: 0.5rem;
          }

          label {
            margin: 0;
            font-weight: normal;
            color: #494b4e;
          }
        }

        label {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 0.5rem;

          font-family: Nunito;
          font-weight: bold;
          font-size: 15px;

          letter-spacing: -0.24px;
          color: #1d3158;
        }
      }

      .btn.size-xxlg {
        margin-top: 16px;
        max-width: 267px;
        height: 38px;
        width: Min(calc(267px), calc(100%));
        border-radius: 6px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        margin-top: 1rem;
      }

      .error {
        margin-top: 1rem;
        color: red;
      }
    }
  }
}
