@import "../../assets/styles/scss/variable";
.header {
  max-width: 1120px;
  width: 100%;
  height: 106px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      width: 300px;
    }
  }

  a {
    text-decoration: none;
  }

  .nav-items {
    display: flex;
    align-items: center;
    // drop-down menu styling
    .dropdown-link {
      position: relative;
      .msg-counter-container {
        position: absolute;
        left: 32px;
        top: -20%;
      }
      .dropdown-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        list-style: none;
        color: $color-black-off;
        transition: all 0.3s ease-in;
        position: relative;

        .profile-info {
          position: relative;
          display: flex;
          align-items: center;
        }

        &:hover {
          color: $color-primary;
          .sub-menu {
            display: block;
          }
        }
        .img-container {
          width: 36px;
          height: 36px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          margin-right: 0.5rem;
        }

        svg {
          width: 0.5rem;
          height: 0.3125rem;
          margin-left: 0.25rem;
        }

        .sub-menu {
          display: none;
          position: absolute;
          top: 100%;
          width: 100%;
          background-color: #fff;
          z-index: 1;
          padding: 1rem 0.75rem;
          text-align: end;

          a {
            text-decoration: none;

            .sub-menu-item {
              list-style: none;
              color: $color-black-off;
              transition: all 0.3s ease-in;

              &:hover {
                color: $color-primary;
              }
            }
          }
        }
      }
    }
    a {
      text-decoration: none;

      .nav-item {
        font-size: 0.875rem;
        list-style: none;
        color: $color-black-off;
        transition: all 0.3s ease-in;

        .nav-item-icon {
          display: flex;
          align-items: baseline;
          svg {
            margin-right: 0.5rem;
          }
        }

        &:hover {
          color: $color-primary;
          svg {
            path {
              fill: #ed1c24 !important;
            }
          }
        }
      }

      .nav-item-active {
        color: $color-primary;
        .nav-item-icon {
          svg {
            path {
              fill: #ed1c24 !important;
            }
          }
        }
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.navigation-mobile {
  display: none;
}

@media screen and (max-width: 1120px) {
  .header {
    padding: 0 20px;
    .navigation-mobile {
      display: flex;

      .menu-icon-container {
        .menu-icon {
          color: $color-primary;
        }
      }

      .nav-items {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 106px;
        left: 0;
        width: 100%;
        padding: 20px 0;
        background: #fff;
        margin: 0px;
        z-index: 10;
        border-top: 1px solid $color-primary;

        .dropdown-link {
          width: 100%;

          .dropdown-item {
            position: relative;
            .msg-counter-container {
              left: 32px;
            }
            &:hover {
              color: $color-primary;
              .sub-menu {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
        }
        a {
          text-decoration: none;
          .nav-item {
            list-style: none;
            color: $color-black-off;
            &:hover {
              color: $color-primary;
            }
          }

          .nav-item-active {
            color: $color-primary;
          }

          margin: 0;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
    .navigation-desktop {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  .header {
    .logo-container {
      svg {
        width: 200px;
      }
    }
  }
}
