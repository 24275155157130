.saved-mod-display-board {
  max-width: 1132px;
  width: 100%;
  margin: 0 auto;
  .instruction {
    color: #ed1c24;
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  .cme-display-card:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
