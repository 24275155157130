.education-menu {
  max-width: 832px;
  width: 100%;

  .item-1 {
    margin-bottom: 4rem;
  }

  .item-2 {
    margin-bottom: 1.125rem;
  }

  .view-all-conf {
    text-align: center;
    a {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #ed1c24;
      opacity: 0.9;
    }
  }
}
