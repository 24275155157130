.reset-pwd-header {
  border-top: 1px solid #e8e8e8;
  padding: 40px 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h3 {
    font-weight: 600;
    max-width: 470px;
    width: 100%;
    font-size: 2rem;
    line-height: 56px;
  }
  p {
    max-width: 304px;
    width: 100%;
    font-size: 0.875rem;
    line-height: 16px;
    color: #444444;
  }
}
