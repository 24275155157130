@import "../../../assets/styles/scss/variable";
.landing-content {
  .hero {
    min-height: 494px;
    display: flex;
    align-items: center;

    position: relative;
    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.65;
      background-image: url("../../../assets/images/landing-hero.png");
      // background-repeat: no-repeat;
      // background-position-y: 80%;
      // background-size: cover;
    }

    .hero-content {
      position: relative;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 auto;

      h1 {
        font-size: 3.25rem;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 4px;
        margin-bottom: 1.125rem;
      }

      h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 5px;

        span {
          margin: 0 0.5rem;
        }
      }
    }
  }

  .info {
    background-color: $bg-color-secondary;
    padding: 0 20px;
    min-height: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      max-width: 594px;
      font-size: 1rem;
      line-height: 22px;
      text-align: center;
    }
  }

  .action {
    min-height: 293px;
    background-color: $bg-color-tertiary;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    .action-content {
      max-width: 928px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 950px) {
  .landing-content {
    .action {
      padding: 40px 20px;
      .action-content {
        flex-wrap: wrap;
        justify-content: center;
        .action-circle-content {
          width: 33%;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .landing-content {
    .hero {
      &::before {
        background-position-x: 25%;
      }
      .hero-content {
        h1 {
          font-size: 1.75rem;
          line-height: 36px;

          margin-bottom: 1.875rem;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .landing-content {
    .action {
      .action-content {
        .action-circle-content {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .landing-content {
    .hero {
      .hero-content {
        h1 {
          font-size: 1.5rem;
          line-height: 24px;

          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
