.popup {
  outline: 1px solid orangered;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .innerpopup {
    max-width: 736px;
    width: 100%;
    background-color: #fff;
    .report-msg-popup {
      min-height: 435px;
      color: #000000;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      h1 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 56px;
      }
      h3 {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 22px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #494b4e;
      }

      svg {
        width: 3rem;
        height: 3rem;
      }

      .btn.size-lg {
        max-width: 450px;
        width: 100%;
        height: 44px;
      }
    }

    .rate-popup {
      min-height: 435px;
      color: #000000;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      h1 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 56px;
      }
      h3 {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 22px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #494b4e;
      }

      svg {
        width: 3rem;
        height: 3rem;
      }

      .btn.size-lg {
        max-width: 450px;
        width: 100%;
        height: 44px;
      }
    }
  }
}
