.therapy-board {
  display: flex;
  flex-wrap: wrap;
}
.no-post-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  margin: 2.5rem 0;

  p {
    color: #ed1c24;
    font-weight: 700;
    text-transform: uppercase;
  }
}
