@import "../../../../assets/styles/scss/variable";

.contactus-page-form {
  border: 1px solid #f3f3f3;
  background-color: $bg-color-4;
  min-height: 409px;

  .contactus-form-container {
    max-width: 470px;
    width: 100%;
    margin: 0 auto;
    padding: 75px 20px;

    .contactus-form {
      max-width: 382px;
      margin: 0 auto;
      .form-control {
        input:not([type="checkbox"]) {
          max-width: 382px;
        }
        textarea {
          min-height: 180px;
        }

        label {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 0.5rem;
        }
      }

      .btn.size-xxlg {
        max-width: 382px;
        width: Min(calc(382px), calc(100%));
        border-radius: 6px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        margin-top: 1rem;
      }

      .error {
        margin-top: 1rem;
        color: red;
      }
    }
  }
}
