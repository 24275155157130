.cme-display-board {
  max-width: 1132px;
  width: 100%;
  margin: 0 auto;
  .instruction {
    color: #ed1c24;
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  .cme-display-card:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .load-more {
    max-width: 4rem;
    width: 100%;
    margin: 30px auto;
    text-align: center;
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 20px;

    text-decoration-line: underline;

    color: #ed1c24;
    opacity: 0.9;
    cursor: pointer;
  }
}
