.board {
  margin-bottom: 2rem;

  .board-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1.125rem;
  }

  .board-content {
    max-width: 832px;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #f6f5f8;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px -4px rgba(12, 10, 28, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    .no-article-found {
      p {
        color: red;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  a {
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.25rem 0;
    color: #ed1c24;

    mix-blend-mode: normal;
    opacity: 0.9;
  }
}
