.comment-card-container {
  margin-bottom: 1.5rem;

  .comment-card {
    border: 1px solid #dbdbe5;
    border-radius: 4px;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .comment-main {
      display: flex;
      .author-img-cont {
        margin-right: 0.5rem;
        img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }
      }
      .comment-info {
        max-width: 592px;
        width: 100%;
        h3 {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #000;
          margin-bottom: 0.625rem;
        }
      }
    }
    .comment-actions {
      display: flex;
      margin-left: auto;

      & > div:not(:last-child) {
        margin-right: 0.75rem;
      }
      .comment-action {
        color: #ed1c24;
        cursor: pointer;
        align-self: flex-end;
        text-decoration: underline;
        mix-blend-mode: normal;
        opacity: 0.9;
      }
    }
  }

  .reply-container {
    width: 95%;
    margin-left: auto;
  }

  .article-reply-comment {
    width: 95%;
    margin-left: auto;
    margin-top: 1rem;
    .reply-form-container {
      .reply-form {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .form-control {
          width: 81.5%;
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          label {
            font-family: Nunito;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #000000;
          }
          textarea {
            max-width: 100%;
            width: 100%;
            height: 44px;
            border: 1px solid #d2d8df;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
          }
        }

        .btn.size-lg {
          width: 16%;
          height: 44px;
        }
      }
    }
    .error {
      margin-top: 1rem;
      color: red;
    }
  }
}
