.reporting-heading {
  background-color: #fff;
  min-height: 142px;
  display: flex;
  align-items: center;
  border: 1px solid #eaeaea;
  .title-container {
    display: flex;
    justify-content: space-between;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;

    h1 {
      font-weight: 600;
      margin-left: 288px;
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .reporting-heading {
    .title-container {
      text-align: center;
      justify-content: center;

      h1 {
        margin-left: 0;
      }
    }
  }
}
